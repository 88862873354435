<template>
  <div class="accordion mb-6">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" role="tab">
        <b-button block size="sm" class="btn-collapse" @click="open = !open">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <b-row>
              <b-col cols="2" class="d-flex align-items-center">
                <h6 class="text-success mb-0">
                  {{ agenda.type === 1 ? 'Ngày' : 'Tuần' }} {{ id }}
                </h6>
              </b-col>
            </b-row>
            <div class="d-flex">
              <b-button
                size="sm"
                class="icon-chevron"
                @click.stop="open = !open"
              >
                <v-icon class="p-0" size="24"
                  >mdi-{{ open ? 'chevron-up' : 'chevron-down' }}</v-icon
                >
              </b-button>
            </div>
          </div>
        </b-button>
      </b-card-header>
      <b-collapse
        :id="`accordion-${id}`"
        v-model="open"
        visible
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-card-body>
          <div class="text-right">
            <b-button
              class="btn btn-success"
              type="button"
              @click="openModalActivity"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
              </span>
              {{
                agenda.classify === SCHEDULE_TYPES.LESSON
                  ? 'Thêm bài học'
                  : 'Thêm hoạt động'
              }}
            </b-button>
          </div>
          <div class="py-6">
            <template-table
              :data="agenda.agendas"
              :column="headers"
              :selectAction="false"
              :tableAction="false"
              :searchAction="false"
              :pagingAction="false"
            >
              <template v-slot:body="{ item }">
                <template v-if="isCommonActivity">
                  <td>{{ getActivityType(item.classify) }}</td>
                  <td>{{ getActivityName(item) }}</td>
                </template>

                <template v-if="isFreeActivity">
                  <td>{{ item.name }}</td>
                  <td>{{ getDetailActivity(item) }}</td>
                </template>

                <template v-if="isKpiActivity">
                  <td>{{ getActivityType(item.classify) }}</td>
                  <td>{{ getDetailActivity(item) }}</td>
                </template>

                <template v-if="isLesson">
                  <td>{{ item.lesson.name }}</td>
                  <td>{{ getLessonType(item.lesson.type) }}</td>
                  <td>{{ item.lesson.lessonModule.name }}</td>
                  <td class="text-center">
                    {{ item.lesson.lessonLevel.name }}
                  </td>
                </template>
                <td>
                  <div class="d-flex justify-content-around align-items-center">
                    <b-button
                      class="btn btn-edit"
                      size="sm"
                      type="button"
                      @click="openModalEditActivity(item)"
                    >
                      <span class="menu-icon svg-icon svg-icon-sm mr-0">
                        <inline-svg
                          class="svg-icon mr-0"
                          src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                        />
                      </span>
                    </b-button>
                    <b-button
                      class="btn btn-delete ml-2"
                      size="sm"
                      type="button"
                      @click="showPopupDelete(item)"
                    >
                      <span class="menu-icon svg-icon svg-icon-sm mr-0">
                        <inline-svg
                          class="svg-icon mr-0"
                          src="/media/svg/icons/Neolex/Basic/trash.svg"
                        />
                      </span>
                    </b-button>
                  </div>
                </td>
              </template>
            </template-table>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import { SET_STATE_MODAL } from '@/core/services/store/movementRoute.module';
import { MODAL_TYPE, SCHEDULE_TYPES } from '../../../../core/plugins/constants';
const { mapMutations } = createNamespacedHelpers('movementRoute');
export default {
  name: 'CollapseSchedule',
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    agenda: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      open: true,
      SCHEDULE_TYPES,
    };
  },
  computed: {
    isLesson() {
      return this.agenda?.classify === SCHEDULE_TYPES.LESSON;
    },
    isCommonActivity() {
      return this.agenda?.classify === SCHEDULE_TYPES.COMMON;
    },
    isFreeActivity() {
      return this.agenda?.classify === SCHEDULE_TYPES.FREE;
    },
    isKpiActivity() {
      return this.agenda?.classify === SCHEDULE_TYPES.KPI;
    },
  },
  methods: {
    ...mapMutations({ SET_STATE_MODAL }),
    openModalActivity() {
      this.$emit('show', {
        agenda: this.agenda,
        activityId: null,
        type: MODAL_TYPE.CREATE,
      });
    },
    openModalEditActivity(item) {
      this.$emit('show', {
        agenda: this.agenda,
        activityId: item.id,
        type: MODAL_TYPE.EDIT,
      });
    },
    getActivityType(type) {
      if (this.isCommonActivity) {
        switch (type) {
          case 1:
            return 'Tương tác cá nhân';
          case 2:
            return 'Tương tác nhóm';
          case 3:
            return 'Khảo sát';
          case 5:
            return 'Cập nhật hồ sơ';

          default:
            return 'Đánh giá đầu ra';
        }
      }

      if (this.isKpiActivity) {
        switch (type) {
          case 1:
            return 'Đo huyết áp';
          case 2:
            return 'Đo cân nặng';
          case 3:
            return 'Nhập cảm xúc';
          case 4:
            return 'Cập nhật bữa ăn';

          default:
            return 'Vận động';
        }
      }
    },
    getActivityName(item) {
      if (item.classify !== 3) return item.name;

      if (item.classify === 4) return 'Đánh giá đầu ra';
      return item.survey.name;
    },
    getDetailActivity(item) {
      if (item.timesPerDay) return `${item.timesPerDay} lần/ngày`;

      return `${item.timesOfActivity} phút/ngày`;
    },
    getLessonType(type) {
      switch (type) {
        case 1:
          return 'Bắt buộc';
        case 2:
          return 'Tùy chọn';

        default:
          return 'Bài quiz cấp độ';
      }
    },
    showPopupDelete(item) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn xoá dữ liệu này không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.deleteAgenda(item);
          }
        }.bind(this),
      );
    },
    deleteAgenda(item) {
      if (this.isCommonActivity) return this.deleteAgendaGeneral(item.id);
      if (this.isFreeActivity) return this.deleteAgendaFree(item.id);
      if (this.isKpiActivity) return this.deleteAgendaKpi(item.id);

      this.deleteAgendaLesson(item.id);
    },
    async deleteAgendaGeneral(id) {
      try {
        this.$store.commit('context/setLoading', true);
        const { meta, error } = await this.$api.delete('/Agenda/General', {
          params: {
            id,
          },
        });

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }

        this.$emit('delete');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    async deleteAgendaFree(id) {
      try {
        this.$store.commit('context/setLoading', true);
        const { meta, error } = await this.$api.delete('/Agenda/Free', {
          params: {
            id,
          },
        });

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }

        this.$emit('delete');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    async deleteAgendaKpi(id) {
      try {
        this.$store.commit('context/setLoading', true);
        const { meta, error } = await this.$api.delete('/Agenda/Kpi', {
          params: {
            id,
          },
        });

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }

        this.$emit('delete');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    async deleteAgendaLesson(id) {
      try {
        this.$store.commit('context/setLoading', true);
        const { meta, error } = await this.$api.delete('/Agenda/Lesson', {
          params: {
            id,
          },
        });

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }

        this.$emit('delete');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-collapse {
  background-color: #fff !important;
  border-color: #fff !important;
  padding: 8px 24px;
}

.icon-chevron {
  background-color: #fff !important;
  border-color: #fff !important;
}

.btn-edit::v-deep {
  background: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}
.btn-delete::v-deep {
  background: #ffebef !important;
  border-color: #ffebef !important;
  color: #ff5756 !important;
}

.accordion:last-child {
  margin-bottom: 0 !important;
}
</style>
